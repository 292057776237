import IMask from 'imask';

// const phone = document.querySelector('.phone-mask')
// console.log('telefone:', phone);

const phones = Array.from(document.querySelectorAll('.br-cellphone'));
if (phones.length) {
  phones.forEach(phone => {
    IMask(phone, {
      mask: "(00) 90000-0000"
    })
  })
}

const docs = Array.from(document.querySelectorAll('.br-doc'));
if (docs.length) {
  docs.forEach(doc => {
    IMask(doc, {
      mask: [{
          mask: '000.000.000-00',
        },
        {
          mask: '00.000.000/0000-00',
        },
      ]
    })
  })
}

const dates = Array.from(document.querySelectorAll('.br-date'));
if (dates.length) {
  dates.forEach(date => {
    IMask(date, {
      mask: "00/00/0000"
    })
  })
}
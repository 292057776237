const tabs = Array.from(document.querySelectorAll('.tabs'))

const selectTab = (tabBlock, tab) => {
  tab = tab.closest('div.tab')
  let tabs = Array.from(tab.parentNode.querySelectorAll('.tab'))

  let tabIndex = tabs.indexOf(tab)
  
  tabs.forEach(t => t.classList.remove('selected'))
  tab.classList.add('selected')

  let tabContents = Array.from(tabBlock.querySelectorAll('.tabs-content .tab'))

  if (tabContents.length && tabContents[tabIndex]) {
    tabContents.forEach( contentBlock => contentBlock.classList.remove('active'))
    tabContents[tabIndex].classList.add('active')
  }
}

if (tabs.length) {
  tabs.forEach( tabBlock => {
   
    let tabsInHeader = Array.from(tabBlock.querySelectorAll('.tabs-header > .tab'))
    if (tabsInHeader.length) {
      selectTab(tabBlock, tabsInHeader[0])
     
      tabsInHeader.forEach(tab => {
        tab.addEventListener('click', event => selectTab(tabBlock, event.target))
      })
    }

  })
}


// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// init Swiper:
const mySwiper = new Swiper(".banner-slider.swiper-container", {
  // Optional parameters
  // direction: "vertical",
  loop: true,

  // autoplay
  autoplay: {
    delay: 7500,
    disableOnInteraction: true,
  },

  // disable short swipes
  // shortSwipes: true,
  // longSwipesRatio: 0.1,

  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
    type: 'bullets'
  },

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  // mousewheel: {
  //   sensitivity: 10,
  // }

  // And if we need scrollbar
  // scrollbar: {
  //   el: ".swiper-scrollbar",
  // },
});

const customersSlider = new Swiper("#customers-slider.swiper-container", {
  // Optional parameters
  // direction: "vertical",
  loop: true,

  // autoplay
  autoplay: {
    delay: 3500,
    disableOnInteraction: true,
  },

  // disable short swipes
  // shortSwipes: true,
  // longSwipesRatio: 0.1,

  // If we need pagination
  // pagination: {
  //   el: ".swiper-pagination",
  //   type: 'bullets'
  // },
  // 
  // Navigation arrows
  // navigation: {
  //   nextEl: ".swiper-button-next",
  //   prevEl: ".swiper-button-prev",
  // },

  // mousewheel: {
  //   sensitivity: 10,
  // }

  // And if we need scrollbar
  // scrollbar: {
  //   el: ".swiper-scrollbar",
  // },
});
// 
const staffSlider = new Swiper("#staff-slider.swiper-container", {
  // Optional parameters
  // direction: "vertical",
  loop: true,

  // autoplay
  autoplay: {
    delay: 10000,
    disableOnInteraction: true,
  },

  // disable short swipes
  // shortSwipes: true,
  // longSwipesRatio: 0.1,

  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
    type: 'bullets'
  },

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  // mousewheel: {
  //   sensitivity: 10,
  // }

  // And if we need scrollbar
  // scrollbar: {
  //   el: ".swiper-scrollbar",
  // },
});
let prevScrollpos = window.pageYOffset;
window.addEventListener("scroll", () => {
  let currentScrollPos = window.pageYOffset;
  const mainNav = document.getElementById("main-nav");

  if (currentScrollPos < 60) {
    mainNav.classList.remove('fixed');
    mainNav.classList.remove('hidden');
    mainNav.classList.add('basic');
  } else if (prevScrollpos > currentScrollPos) {
    mainNav.classList.add('fixed');
    mainNav.classList.remove('basic');
    mainNav.classList.remove('hidden')
  } else {
    mainNav.classList.add('hidden');
  }
  prevScrollpos = currentScrollPos;
})
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

// const images = require.context('../images', true)
// export const imagePath = (name) => images(name, true)

// require.context('../images', true)

import "normalize.css"
import "../css/application_milligram.sass"
import "../css/application.styl"

import '../application/slider'
import '../application/tabs'
import '../application/form_masks'
import '../application/pages/e_terminal'
import '../application/main_navigation'
import '../application/nav_top_responsive'
import '../application/main_nav_responsive'